<template>
  <div class="rc-home">
    <HomePageHero />
    <article
      :class="pageColourMode"
      :style="`background-image: url(${pageBackgroundImage})`"
    >
      <ul v-if="pageData && pageData.pageBuilder.length">
        <PageBuilderBlocks
          v-for="block in pageData.pageBuilder"
          :key="block.id"
          :block="block"
          :darkMode="darkMode"
        />
      </ul>
    </article>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { htmlDecode } from '@vue-storefront/core/filters';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import HomePageHero from 'theme/components/theme/bikebuilder/external/HomePageHero';
import PageBuilderBlocks from 'theme/components/theme/bikebuilder/external/craftcms/PageBuilderBlocks';

export default {
  name: 'RibbleHome',
  components: { HomePageHero, PageBuilderBlocks },
  computed: {
    ...mapGetters('craftCms', ['pageData']),
    darkMode() {
      if (!this.pageData) return null;
      return this.pageData.darkMode;
    },
    pageColourMode() {
      if (!this.pageData || !this.darkMode) return 'bg-black text-white';
      return this.darkMode === 'on'
        ? 'bg-black text-white'
        : 'bg-white text-gray-800';
    },
    pageBackgroundImage() {
      const hasBackground =
        this.pageData &&
        this.pageData.pageBackground &&
        this.pageData.pageBackground[0];
      return hasBackground ? this.pageData.pageBackground[0].url : null;
    }
  },
  async asyncData({ store, route }) {
    const token = route.query['token'] || null;
    const uri = 'ribble-home';
    const storeCode = currentStoreView().storeCode;
    const payload = {
      storeCode: storeCode,
      uri,
      token
    };
    // must use await to allow loading server side
    await Promise.all([
      store.dispatch('craftCms/updateBlogPostListing', payload),
      store.dispatch('craftCms/updateHomeHero', { storeCode }),
      store.dispatch('craftCms/updatePageData', payload)
    ]);
  },
  metaInfo() {
    return {
      title: this.pageData ? htmlDecode(this.pageData.metaTitle) : null,
      meta: [
        this.pageData
          ? {
              vmid: 'description',
              name: 'description',
              content: htmlDecode(this.pageData.metaDescription)
            }
          : {},
        this.pageData
          ? {
              vmid: 'keywords',
              name: 'keywords',
              content: htmlDecode(this.pageData.metaKeywords)
            }
          : {}
      ]
    };
  }
};
</script>

<style scoped>
.rc-home {
  position: relative;
  z-index: 1;
}
.rc-hidden {
  display: none;
}
@media (min-width: 640px) {
  .rc-hidden {
    display: block;
  }
}
</style>
